import React from 'react';
import LocalizationService from 'services/localization.service';
import ButtonRect from 'components/FormElements/Button/ButtonRect';
import HelperService from 'services/helper.service';
import 'assets/scss/Confirm.scss';
import Parser from 'html-react-parser';

export default class ConfirmComponent extends React.Component {
  constructor(props) {
    super(props);
    LocalizationService.bootstrap(this);
  }

  componentDidMount = () => {
    HelperService.gtm({
      event: "Confirmation PopUp Loaded",
      page: "Confirmation PopUp"
    });
  }

  render() {
    let title = '';
    if (this.props.title) {
      if (this.props.title[1]) {
        // Literal
        title = this.props.title[0];
      } else {
        title = this.__(this.props.title[0]);
      }
    }

    let subtitle = undefined;

    if (this.props.subtitle) {
      if (this.props.subtitle instanceof Array) {
        if (
          this.props.subtitle.length === 2 &&
          typeof this.props.subtitle[1] === 'boolean'
        ) {
          if (this.props.subtitle[1] === true) {
            subtitle = this.props.subtitle[0];
          } else {
            subtitle = this.__(this.props.subtitle[0], true);
          }
        } else {
          subtitle = [];
          this.props.subtitle.map(value => {
            if (value instanceof Array) {
              if (value.length === 2 && value[1] === true) {
                // Literal
                subtitle.push(value[0]);
              } else {
                subtitle.push(this.__(value[0], true));
              }
            } else {
              subtitle.push(this.__(value, true));
            }
            return '';
          });
          subtitle = subtitle.join(' ');
        }
      } else {
        subtitle = this.props.subtitle;
      }
    }

    const cancelAction = () => {
      HelperService.gtm({
        event: "Canceled",
        page: "Confirmation PopUp"
      });
      HelperService.showModal(false);
      if (this.props.cancel) {
        this.props.cancel.apply(this);
      }
    };

    const confirmAction = () => {
      if (this.props.confirm) {
        HelperService.gtm({
          event: "Confirmed",
          page: "Confirmation PopUp"
        });
        this.props.confirm.apply(this);
      }

      if (this.props.save) {
        HelperService.gtm({
          event: "Saved",
          page: "Confirmation PopUp"
        });
        this.props.save.apply(this);
      }
      HelperService.showModal(false);
    };

    const saveButtonLabel = () => {
      if (this.props.save) {
        return this.__('S04.05_B2');
      } else {
        return this.__('ACTION_SIM');
      }
    };

    return (
      <div className="confirm">
        <div className="msg">
          <h1>{title}</h1>
          <div className="subtitle">{Parser(subtitle)}</div>
        </div>
        <div className="btns">
          <ButtonRect
            icon="close"
            iconPosition="right"
            label={this.__('ACTION_NAO')}
            type="submit"
            variant="secondary"
            onClick={cancelAction}
          />
          <ButtonRect
            icon="check"
            iconPosition="right"
            label={saveButtonLabel()}
            onClick={confirmAction}
            type="submit"
            variant="primary"
          />
        </div>
      </div>
    );
  }
}
