import React from 'react';
import Icon from 'components/Icon';
import './index.scss';
import LocalizationService from 'services/localization.service';

export default class OrientationLock extends React.Component {
  render() {
    return (
      <div id="orientation-lock">
        <div className="icon">
          <Icon color="selection" icon="orientation" width={12} />
        </div>
        <div>
          {/* Por favor, oriente o seu dispositivo na posição vertical. */}
          {LocalizationService.getText('DEVICE_UPRIGHT_POSITION')}
        </div>
      </div>
    );
  }
}
