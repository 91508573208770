import React from 'react';
import ConfirmComponent from 'components/Modal/Confirm';
import ObserverService from 'services/observer.service';
import LocalizationService from 'services/localization.service';
import HelperService from 'services/helper.service';

export default class ConfirmPageExit extends React.Component {
  constructor(props) {
    super(props);
    LocalizationService.bootstrap(this);
  }

  params = (url, title, subtitle) => {
    /*this.setState({
      url: url,
      title: title,
      subtitle: subtitle,
    });*/
    HelperService.gtm({
      event: "Exit Page Confirm PopUp Loaded",
      page: "Exit Page Confirm PopUp"
    });

    HelperService.showModal(
      <ConfirmComponent
        title={title}
        subtitle={subtitle}
        confirm={async () => {
          HelperService.showLargeModal(false);
          if (url) {
            HelperService.gtm({
              event: "Exit",
              page: "Exit Page Confirm PopUp"
            });
            ObserverService.link.redirect.next(url);
          }
        }}
      />,
    );
  };

  render() {
    if (this.state.url && this.state.title && this.state.subtitle) {
      return (
        <ConfirmComponent
          title={['S03.05_B1_TITLE']}
          subtitle={['S03.05_B1_SUBTITLE']}
          confirm={async () => {
            HelperService.showLargeModal(false);
            ObserverService.link.redirect.next(this.props.url);
          }}
        />
      );
    } else {
      return null;
    }
  }
}
