import React from 'react';
import UserService from 'services/user.service';
import HelperService from 'services/helper.service';
import LocalizationService from 'services/localization.service';

class PrimusUser extends React.Component {
  innerState = {
    loggedin: false,
    user: {},
    timeout: 60 * 30,
    expirydate: null,
  };

  constructor() {
    super();
    this.checkUserPersistent();
  }

  hasSession = () => {
    return this.innerState.loggedin;
  };

  isAdmin = () => {
    return this.innerState.user.isAdmin;
  };

  isPropositionManager = () => {
    return (
      this.innerState.user.User.Permissions.filter(
        val => val.PartnerNetPermissionId === 2 && val.HasAccess,
      ).length === 1
    );
  };

  isPermissionAccessAllowed = (permissionKey) => {
    const permissionId = UserService.getPermissionIdByPermissionKey(permissionKey);
    return (
      this.hasSession()
      && this.innerState.user.User.Permissions.filter(
        val => UserService.getPermissionHierarchyByPermissionKey(val.Key).indexOf(permissionId) > -1 && val.HasAccess,
      ).length > 0
    );
  };

  isActive = () => {
    return this.innerState.user.isActive;
  };

  getToken = () => {
    if (this.innerState.user && this.innerState.user.Session) {
      return this.innerState.user.Session.SessionId
        ? this.innerState.user.Session.SessionId
        : false;
    } else {
      return false;
    }
  };

  getRequestHeaders = () => {
    const token = this.getToken();
    let ret = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (token) {
      ret.headers['X-Primus-Token'] = token;
    }

    ret.headers[
      'X-Primus-LanguageKey'
    ] = LocalizationService.getCurrentLanguageKey();

    return ret;
  };

  destroySession = async () => {
    let ret = null;

    await UserService.logout().then(
      _result => {
        let newState = {
          loggedin: false,
          expirydate: null,
          user: {},
        };
        let state = this.innerState;
        this.innerState = {
          ...state,
          ...newState,
        };

        // Clear the cookies on express logout
        HelperService.deleteCookie('primusPersistentSession');
        HelperService.deleteCookie('translationKeys');
        sessionStorage.removeItem("primusPersistentSession");

        ret = _result;
      },
      error => {
        // Clear the cookies on express logout
        HelperService.deleteCookie('primusPersistentSession');
        HelperService.deleteCookie('translationKeys');
        sessionStorage.removeItem("primusPersistentSession");

        ret = error;
      },
    );

    return ret;
  };

  doLogin = async (username, password, rememberUser = false) => {
    // HelperService.deleteCookie('primusPersistentSession');
    // HelperService.deleteCookie('translationKeys');

    return await UserService.login({
      Username: username,
      Password: password,
    }).then(
      async result => {
        if (result.data.LoginResult === 0) {
          this.logInUser(result);
          // Get Profile info:
          await UserService.getUserProfileInfo({
            SessionId: result.data['Session']['SessionId'],
          }).then(
            async res => {
              result.data = { Profile: res.data, ...result.data };
              await UserService.getUser({ IdUser: result.data.UserId })
                .then(r => {
                  result.data = { User: r.data, ...result.data };
                })
                .then(r => {
                  this.logInUser(result);

                  if (rememberUser) {
                    // User will be remembered
                    this.saveUserDataToLocal({ data: result.data });
                  } else {
                    this.saveUserDataToSession({ data: result.data });
                  }
                  HelperService.setCookie('firstTimeInAppAfterLogin', true);
                });
            },
            error => {
              HelperService.showGenericError(error);
            },
          );

          return result;
        } else {
          throw result.data.LoginResult;
        }
      },
      error => {
        HelperService.showGenericError(error);
      },
    );
  };

  saveUserDataToLocal = data => {
    if (data) {
      HelperService.setCookie('primusPersistentSession', data);
    } else {
      return false;
    }
  };

  saveUserDataToSession = data => {
    if (data) {
      HelperService.setSession('primusPersistentSession', data);
    } else {
      return false;
    }
  };

  checkUserPersistent = () => {
    let oldSession = HelperService.getCookie('primusPersistentSession');
    if (oldSession) {
      return this.logInUser(oldSession);
    } else {
      return false;
    }
  };

  logInUser = result => {
    let _state = this.innerState;
    let isAdmin = false;
    let isActive = false;

    if (result.data.Permissions) {
      if (
        result.data.Permissions.filter(p => p.Key === 'ADMINISTRACAO').length >
        0
      ) {
        isAdmin = true;
      }
    }

    if (!isAdmin && result.data.User) {
      // Verificar no outro lado.
      result.data.User.Permissions.map(val => {
        if (val.Key === 'ADMINISTRACAO' && val.HasAccess === true) {
          isAdmin = true;
        }
        return '';
      });
    }

    if (result.data.User && result.data.User.IsActive) {
      isActive = result.data.User.IsActive;
    }

    //Critérios de pesquisa para a página de gestão de propostas
    let proposalManagementFilters = {
      isActive: false,
      creditTypeId: undefined,
      proposalStateId: undefined,
      searchText: undefined,
      providerId: undefined,
      userId: undefined,
      descendingDateSortOrder: undefined,
    };
    if (result.data.proposalManagementFilters)
      proposalManagementFilters = result.data.proposalManagementFilters;

    let newUser = {
      loggedin: true,
      user: {
        ...{ userName: result.data.UserName },
        ...{ userId: result.data.UserId },
        ...{ isAdmin: isAdmin },
        ...{ isActive: isActive },
        ...{ fullName: result.data.FullName },
        ...{ Partner: result.data.PartnerInfo },
        ...{ Session: result.data.Session },
        ...{ Profile: result.data.Profile },
        ...{ User: result.data.User },
        ...{ proposalManagementFilters: proposalManagementFilters },
        ...{ regulationAgreementDate: result.data.RegulationAgreementDate },
      },
    };

    this.innerState = {
      ..._state,
      ...newUser,
    };

    this.activateUserTimeout(this.innerState.user.Session.ExpirationDate);
  };

  resetPassword = async userEmail => {
    // Call API here
    return await UserService.resetPassword({
      Email: userEmail,
      ActivationLink: window.location.origin + '/alterar-palavra-passe',
    }).then(
      result => {
        return true;
      },
      error => {
        HelperService.showGenericError(error);
      },
    );
  };

  changeUserPassword = async (token, pass1, pass2) => {
    return await UserService.validateTicket({
      TicketNumber: token,
      Password: pass1,
      PasswordConfirm: pass2,
      AppUrl: window.location.origin + '/alterar-palavra-passe',
    });
  };

  getProp = prop => {
    if (prop) {
      return this.innerState.user[prop];
    } else {
      return this.innerState.user;
    }
  };

  hasPermission = async (permissionKeyOrString = null, theUserId = null) => {
    if (this.hasSession()) {
      if (typeof permissionKeyOrString === 'string') {
        let returnObj = false;
        await UserService.getUser({
          IdUser: theUserId || this.getProp('userId'),
        }).then(
          result => {
            const data = result.data;
            if (data.Success === true) {
              data.Permissions.map(value => {
                if (
                  permissionKeyOrString === value.Key &&
                  value.HasAccess === true
                ) {
                  returnObj = true;
                }
                return '';
              });
            }
            returnObj = false;
          },
          error => {
            HelperService.showGenericError(error);
          },
        );
        return returnObj;
      } else if (typeof permissionKeyOrString === 'number') {
        let returnObj = false;
        await UserService.getUser({
          IdUser: theUserId || this.getProp('userId'),
        }).then(
          result => {
            const data = result.data;
            if (data.Success === true) {
              data.Permissions.map(value => {
                if (
                  permissionKeyOrString === value.PartnerNetPermissionId &&
                  value.HasAccess === true
                ) {
                  returnObj = true;
                }
                return '';
              });
            }
            returnObj = false;
          },
          error => {
            HelperService.showGenericError(error);
          },
        );
        return returnObj;
      } else {
        //# ITS NOT SUPPORTED
        HelperService.log(
          'This is not supported. Only can receive number|string.. OBJECT was received!',
        );
        return false;
      }
    } else {
      //# Does not has a session
      HelperService.log('This user is not logged in.... BUG?');
      return false;
    }
  };

  activateUserTimeout = sessionDate => {
    const currentDate = new Date().getTime();
    const maxDate = new Date(sessionDate).getTime();
    const difference = maxDate - currentDate;

    HelperService.log(
      'Expira a ' + new Date(new Date().getTime() + difference),
      'orange; font-weight: bold; text-decoration: underline overline; text-transform: uppercase;',
    );

    this.increaseUserTimer();

    // Guardar no cookie
    const oldData = HelperService.getCookie('primusPersistentSession');
    if (oldData) {
      // If its not a new login
      oldData.data.Session.ExpirationDate = new Date(
        new Date().getTime() + difference,
      ).toISOString();
      HelperService.setCookie('primusPersistentSession', oldData);
    }

    if (difference > 0) {
      setTimeout(() => {
        // check first
        if (this.userTimer - difference < currentDate) {
          this.renewUserSession();
        } else {
          HelperService.redirect('/terminar-sessao');
        }
        return false;
      }, difference / 2);
    } else {
      HelperService.redirect('/terminar-sessao');
      return false;
    }
  };

  increaseUserTimer = () => {
    HelperService.log('User timer for activity was increased');
    this.userTimer = new Date().getTime();
  };

  renewUserSession = async () => {
    HelperService.gtm({
      event: "Renew User Session",
    });
    return await UserService.ping({
      SessionId: this.innerState.user.Session.SessionId,
    }).then(
      async result => {
        HelperService.log(
          'Pinging the Session timer service was a success!',
          'blue',
        );
        HelperService.gtm({
          event: "Renew User Session Success",
          data: result.data
        });
        if (result.data.ExpirationDate) {
          const oldData = HelperService.getCookie('primusPersistentSession');
          HelperService.log('An expiration date was received', 'blue');
          if (oldData && oldData.data && oldData.data.Session) {
            oldData.data.Session.ExpirationDate = result.data.ExpirationDate;
            HelperService.setCookie('primusPersistentSession', oldData);
          }
          this.activateUserTimeout(result.data.ExpirationDate);
        } else {
          HelperService.log('An expiration date was not found', 'red');
          HelperService.redirect('/terminar-sessao', true);
          return false;
        }
      },
      error => {
        HelperService.gtm({
          event: "Failed to Renew User Session",
          reason: error
        });
        HelperService.showGenericError(error);
      },
    );
  };

  setProposalManagementFilters = proposalManagementFilters => {
    const oldData = HelperService.getCookie('primusPersistentSession');
    if (oldData) {
      oldData.data.proposalManagementFilters = proposalManagementFilters;
      HelperService.setCookie('primusPersistentSession', oldData);
    }
  };
}

export default new PrimusUser();
