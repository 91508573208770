import axios from 'axios';

var apiUrl = false;

if (process.env) {
  console.info('REACT_APP_ENVIRONMENT: ', process.env.REACT_APP_ENVIRONMENT.toLowerCase());
  switch (process.env.REACT_APP_ENVIRONMENT.toLowerCase()) {
    default:
    case 'dev':
      apiUrl = process.env.REACT_APP_API_URL_DEV;
      console.info('API: Dev');
      break;
    case 'prod':
      apiUrl = process.env.REACT_APP_API_URL_PROD;
      console.info('API: Prod');
      break;
    case 'uat':
      apiUrl = process.env.REACT_APP_API_URL_UAT;
      console.info('API: Uat');
      break;
  }
} else {
  console.error('Was not able to read ENV file properly!');
}

var axiosData = {
  baseURL: apiUrl,

  // Dirceu
  //baseURL: 'http://NELT1158/BPrimusGoDigital.WebAPI/api',

  // Bruno
  //baseURL: 'http://NELT973/BPrimusGoDigital.WebAPI/api',

  //Andre
  //baseURL: 'http://NELT924/BPrimusGoDigital.WebAPI/api',

};

const API = axios.create(axiosData);

export default API;
