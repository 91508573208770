import React from 'react';
import './ButtonRect.scss';
import { Button } from 'react-bootstrap';
import Icon from 'components/Icon';

export default class ButtonRect extends React.Component {
  render() {
    const dataProps = [];
    Object.keys(this.props).map(keyName => {
      if (keyName.indexOf('data-') > -1) {
        dataProps[keyName] = this.props[keyName];
      }
      return '';
    });

    const iconProps = {
      icon: this.props.icon,
      color: this.props.iconColor,
      width: this.props.iconWidth,
    };
    let buttonClass;
    let iconPosition;
    let size;
    if (this.props.iconPosition || this.props.size) {
      iconPosition = this.props.iconPosition ? this.props.iconPosition : '';
      size = this.props.size ? this.props.size : '';
      buttonClass = `btn-rect ${iconPosition} ${size}`;
    } else {
      buttonClass = `btn-rect`;
    }
    return (
      <Button
        active={this.props.active}
        block={this.props.block}
        className={buttonClass}
        disabled={this.props.disabled}
        type={this.props.type}
        title={this.props.title}
        variant={this.props.variant}
        onClick={this.props.onClick}
        style={this.props.style}
        {...dataProps}
      >
        {this.props.label ? (
          <span
            className={`label${this.props.center ? ' center' : ''}`}
            {...dataProps}
          >
            {this.props.label}
          </span>
        ) : (
          ''
        )}
        {this.props.icon && !this.props.iconColor ? (
          <span className="icon" {...dataProps}>
            <Icon {...iconProps} />
          </span>
        ) : this.props.iconColor ? (
          <span className="icon alternate" {...dataProps}>
            <Icon {...iconProps} />
          </span>
        ) : null}
      </Button>
    );
  }
}
