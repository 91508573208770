import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import './index.scss';

export default class LoadingElement extends React.Component {
  // https://www.npmjs.com/package/react-loader-spinner
  // example: https://mhnpd.github.io/react-loader-spinner/?path=/story/loader--rings

  render() {
    return (
      <Loader
        className="inline-loader"
        type="ThreeDots"
        color="#FFF"
        height={30}
        width={30}
      />
    );
  }
}
