import React from 'react';
import './index.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import LocalizationService from 'services/localization.service';
import HelperService from 'services/helper.service';

export default class CookieConsent extends React.Component {
  state = {
    isVisible: true,
    cookieName: 'primusUserConsentCookie',
  };

  constructor(props) {
    super(props);
    LocalizationService.bootstrap(this);
  }

  componentDidMount = () => {
    this.checkUserConsentCookies(this.state.cookieName);
  };

  checkUserConsentCookies = cookieName => {
    const cookieData = HelperService.getCookie(cookieName);
    if (cookieData) {
      // There is a cookie!
      this.setState({ isVisible: false });
    } else {
      HelperService.gtm({
        event: "Showing Cookie Consent PopUp"
      });
    }
  };

  saveUserChoice = () => {
    HelperService.setCookie(this.state.cookieName, 1);
    // Convert from sessionStorage to localStorage!

    HelperService.gtm({
      event: "Accepting Cookies on Cookie Consent PopUp"
    });

    this.setState({ isVisible: false });
    Object.keys(sessionStorage).map(key => {
      HelperService.setCookie(key, HelperService.getCookie(key));
      return true;
    });
    sessionStorage.clear();
    LocalizationService.listenToCookies();
  };

  render() {
    return this.state.isVisible ? (
      <div className="cookie-consent front">
        <Container>
          <Row>
            <Col xs={6}>
              {this.__('S02.06_LBL1')} &ndash;{' '}
              <NavLink to="/pagina/politica-cookies" key="S02.06_B1">
                {this.__('S02.06_B1')}
              </NavLink>
            </Col>
            <Col xs={6} className="text-right">
              <Button variant="default" onClick={this.saveUserChoice}>
                {this.__('S02.06_B2')}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    ) : null;
  }
}
