import React from 'react';
import { BehaviorSubject } from 'rxjs';
import Parser from 'html-react-parser';
import API from 'config/endpoint';
import LoadingElement from 'components/Loading';
import HelperService from 'services/helper.service';

class LocalizationService extends React.Component {
  hook = {
    translations: new BehaviorSubject(),
    storage: new BehaviorSubject(),
    currentLanguage: 'pt-PT',
    missingKeys: [],
    invalidKeys: [],
    serviceTimer: 0,
    stash: {},
  };

  constructor() {
    super();
    this.readBrowserLanguage();
    this.listenToCookies();
  }

  bootstrap = _self => {
    return (
      this.hook.translations.subscribe(resources => {
        if (!_self.state) {
          _self.state = {};
        }
        if (typeof _self.state.translations !== 'object') {
          _self.state.translations = {};
        }
        try {
          if (_self._ismounted && _self.updater.isMounted(_self)) {
            HelperService.log('Actualizou traduções!', 'green');
            _self.setState({ translations: resources });
          } else {
            HelperService.log('Actualizou traduções sem o SET STATE');
            _self.state.translations = resources;
          }
        } catch (e) {
          HelperService.log(
            'Actualizou traduções sem o SET STATE ... Houve um erro e foi apanhado....',
            'red',
          );
          _self.state.translations = resources;
        }
      }),
      (_self.getText = _self.__ = (text, needsOnlyText) => {
        if (
          typeof _self.state.translations !== 'undefined' &&
          _self.state.translations[text]
        ) {
          return Parser(_self.state.translations[text]);
        } else {
          if (this.hook.serviceTimer > 0) {
            clearTimeout(this.hook.serviceTimer);
          }
          if (this.hook.invalidKeys.indexOf(text) === -1) {
            this.hook.missingKeys.push(text);
          }
          this.hook.serviceTimer = setTimeout(this.getMissingKeys, 550);

          if (needsOnlyText) {
            return '...';
          } else {
            return <LoadingElement key={text} />;
          }
        }
      }),
      (_self.getTextAsync = _self.__async = async (text, callParser = true) => {
        if (
          typeof _self.state.translations !== 'undefined' &&
          _self.state.translations[text]
        ) {
          return callParser
            ? Parser(_self.state.translations[text])
            : _self.state.translations[text];
        } else {
          if (this.hook.invalidKeys.indexOf(text) === -1) {
            this.hook.missingKeys.push(text);
          }

          return new Promise((resolve, reject) => {
            this.getMissingKeys(() => {
              if (this.hook.stash[this.hook.currentLanguage][text]) {
                resolve(this.hook.stash[this.hook.currentLanguage][text]);
              } else {
                reject(false);
              }
            });
          });
        }
      }),
      (_self.UNSAFE_componentWillMount = () => {
        try {
          _self._ismounted = true;
          HelperService.bindLoad(_self);
          HelperService.log('Boot added successfully', 'green');
        } catch (e) {
          HelperService.log(
            'Error while mounting component!',
            'red; font-size: 40px;',
          );
          console.error('component:', _self);
        }
      }),
      (_self.componentWillUnmount = () => {
        try {
          let cwu = _self.UNSAFE_componentWillUnmount || (() => { });
          cwu();
          _self._ismounted = false;
          HelperService.bindUnload(_self);
          HelperService.log('Unload added successfully', 'green');
        } catch (e) {
          HelperService.log(
            'Error while unmounting component!',
            'red; font-size: 40px;',
          );
          console.error('component:', _self);
        }
      })
    );
  };

  listenToCookies = () => {
    let currentLanguage = HelperService.getCookie('currentLanguage');
    if (!currentLanguage) {
      // Save a default:
      HelperService.setCookie('currentLanguage', this.hook.currentLanguage);
    } else {
      // Apply settings
      this.hook.currentLanguage = currentLanguage;
    }

    // Check for translation keys
    let translationKeys = HelperService.getCookie('translationKeys');

    if (translationKeys) {
      // We store all MultiLang in stash, and then, NEXT() on the language we need to translations
      this.hook.stash = translationKeys;
      this.hook.translations.next(this.hook.stash[this.hook.currentLanguage]);
    } else {
      HelperService.setCookie('translationKeys', {});
    }

    // ObservFunc
    let _observe = () => {
      let pData = HelperService.getCookie('currentLanguage');
      this.hook.translations.next(this.hook.stash[pData]);
    };

    const hasAcceptedCookies = HelperService.getCookie(
      'primusUserConsentCookie',
    );

    // Add the observable
    if (localStorage && hasAcceptedCookies) {
      this.hook.storage = new BehaviorSubject(
        localStorage.currentLanguage,
      ).subscribe(_observe);
    } else if (!hasAcceptedCookies && sessionStorage) {
      this.hook.storage = new BehaviorSubject(
        sessionStorage.currentLanguage,
      ).subscribe(_observe);
    } else {
      this.hook.storage = new BehaviorSubject(document.cookie).subscribe(
        _observe,
      );
    }
  };

  changeLanguage = language => {
    if (Object.keys(this.hook.stash).indexOf(language) > -1) {
      this.hook.currentLanguage = language;
      this.hook.invalidKeys = [];
      HelperService.setCookie('currentLanguage', language);
      this.hook.storage.next(language);
      try {
        document.getElementsByTagName('html')[0].lang = language;
      } catch (e) { }
      return true;
    } else {
      this.hook.invalidKeys = [];
      this.hook.stash[language] = {};
      return this.changeLanguage(language);
    }
  };

  getText = (text, needsOnlyText = false) => {
    if (this.hook.stash[this.hook.currentLanguage][text]) {
      return this.hook.stash[this.hook.currentLanguage][text];
    } else {
      if (this.hook.serviceTimer > 0) {
        clearTimeout(this.hook.serviceTimer);
      }
      if (this.hook.invalidKeys.indexOf(text) === -1) {
        this.hook.missingKeys.push(text);
      }
      this.hook.serviceTimer = setTimeout(this.getMissingKeys, 550);
      if (needsOnlyText) {
        return '...';
      } else {
        return <LoadingElement />;
      }
    }
  };

  getTextAsync = async (text, callParser = true) => {
    if (this.hook.stash[this.hook.currentLanguage][text]) {
      return this.hook.stash[this.hook.currentLanguage][text];
    } else {
      if (this.hook.serviceTimer > 0) {
        clearTimeout(this.hook.serviceTimer);
      }
      if (this.hook.invalidKeys.indexOf(text) === -1) {
        this.hook.missingKeys.push(text);
      }

      return new Promise((resolve, reject) => {
        this.getMissingKeys(() => {
          if (this.hook.stash[this.hook.currentLanguage][text]) {
            resolve(this.hook.stash[this.hook.currentLanguage][text]);
          } else {
            reject(false);
          }
        });
      });
    }
  };

  getMissingKeys = (completedCallback) => {
    // Retreives missing keys from API
    let header = { headers: { 'Content-Type': 'application/json' } };

    clearTimeout(this.hook.serviceTimer);

    const keysToGet = [];

    this.hook.missingKeys.map(el => {
      if (this.hook.invalidKeys.indexOf(el) > -1) {
        return false;
      }
      if (keysToGet.indexOf(el) === -1) {
        keysToGet.push(el);
      }
      return true;
    });

    this.hook.missingKeys = [];

    let data = {
      LanguageCultureName: this.hook.currentLanguage,
      Keys: keysToGet,
    };

    if (keysToGet.length > 0) {
      clearTimeout(this.hook.serviceTimer);
      API.post('/FrontEndResource/GetValuesByKeys', data, header).then(
        result => {
          clearTimeout(this.hook.serviceTimer);

          let tempHolder = {};
          result.data.map(value => {
            const { Name, Value } = value;
            if (
              Value.includes('*** Resource') &&
              Value.includes('Not Found! ***')
            ) {
              tempHolder[Name] = 'ERROR';
            } else {
              tempHolder[Name] = Value;
            }
            return true;
          });

          this.hook.invalidKeys = [...this.hook.invalidKeys, ...keysToGet];

          this.hook.stash[this.hook.currentLanguage] = {
            ...this.hook.stash[this.hook.currentLanguage],
            ...tempHolder,
          };

          this.hook.translations.next(
            this.hook.stash[this.hook.currentLanguage],
          );

          HelperService.setCookie('translationKeys', this.hook.stash);
          if (typeof completedCallback === 'function') {
            completedCallback();
          }
        },
        error => {
          HelperService.log('Serviço de Recursos deixou de responder', 'red');
          clearTimeout(this.hook.serviceTimer);
          if (typeof completedCallback === 'function') {
            completedCallback();
          }
        },
        () => {
          clearTimeout(this.hook.serviceTimer);
          if (typeof completedCallback === 'function') {
            completedCallback();
          }
        },
      );
    } else {
      if (typeof completedCallback === 'function') {
        completedCallback();
      }
    }
  };

  readBrowserLanguage = () => {
    this.hook.currentLanguage = 'pt-PT';
    if (!this.hook.stash[this.hook.currentLanguage]) {
      this.hook.stash[this.hook.currentLanguage] = [];
    }
  };

  getCurrentLanguageKey = () => {
    return this.hook.currentLanguage;
  };
}

export default new LocalizationService();
