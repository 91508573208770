import API from 'config/endpoint';
import PrimusUser from 'User';

class UserService {

  login(data) {
    return API.post(
      '/LogOnMethods/Login',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  changePassword(data) {
    return API.post(
      '/LogOnMethods/ChangePassword',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  logout() {
    return API.post('/LogOnMethods/Logout', {}, PrimusUser.getRequestHeaders());
  }

  resetPassword(data) {
    return API.post(
      '/LogOnMethods/ResetPassword',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  unlockUser(data) {
    return API.post(
      '/LogOnMethods/UnlockUser',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  ping(data) {
    return API.post('/LogOnMethods/Ping', data, PrimusUser.getRequestHeaders());
  }

  getUsers(data) {
    return API.post(
      '/UserMethods/GetUsers',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  getUser(data) {
    return API.post(
      '/UserMethods/GetUser',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  createUser(data) {
    return API.post(
      '/UserMethods/CreateUser',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  saveUser(data) {
    return API.post(
      '/UserMethods/SaveUser',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  getUserProfileInfo(data) {
    return API.post(
      '/UserMethods/GetUserProfileInfo',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  validateTicket(data) {
    return API.post(
      '/UserMethods/ValidateTicket',
      data,
      PrimusUser.getRequestHeaders(),
    );
  }

  updateRegulationAgreementDate() {
    return API.post(
      '/UserMethods/UpdateRegulationAgreementDate',
      {},
      PrimusUser.getRequestHeaders(),
    );
  }

  getRelationshipsWithPartner() {
    return API.get(
      `/UserMethods/GetRelationshipsWithPartner`,
      PrimusUser.getRequestHeaders(),
    );
  }

  getDocumentTypeList() {
    return API.get(
      `/UserMethods/GetDocumentTypeList`,
      PrimusUser.getRequestHeaders(),
    );
  }

  getPermissionIdByPermissionKey(permissionKey) {
    switch (permissionKey) {
      case 'ADMINISTRACAO':
        return 1;
      case 'PREMIUM':
        return 9;
      case 'TODAS_PROPOSTAS_PARCEIRO':
        return 2;
      case 'BASE':
        return 10;
      default:
        return null;
    }
  }
  getPermissionHierarchyByPermissionKey(permissionKey) {
    switch (permissionKey) {
      case 'ADMINISTRACAO':
        return [1, 9, 2, 10];
      case 'PREMIUM':
        return [9, 2, 10];
      case 'TODAS_PROPOSTAS_PARCEIRO':
        return [2, 10];
      case 'BASE':
        return [10];
      default:
        return [];
    }
  }
  getPermissionHierarchyByPermissionId(permissionId) {
    switch (permissionId) {
      case 1:
        return [1, 9, 2, 10];
      case 9:
        return [9, 2, 10];
      case 2:
        return [2, 10];
      case 10:
        return [10];
      default:
        return [];
    }
  }
  getPermissionInvertedHierarchyByPermissionId(permissionId) {
    switch (permissionId) {
      case 1:
        return [];
      case 9:
        return [1];
      case 2:
        return [1, 9];
      case 10:
        return [1, 9, 2];
      default:
        return [];
    }
  }
}

export default new UserService();
