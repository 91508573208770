import React, { lazy, Suspense } from 'react';
import {
  //BrowserRouter as Router,
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import OrientationLock from 'components/OrientationLock';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as ProjectRoutes from './config/routes.json';

import * as AllAvailableResources from './Modules';
import LoadingPage from './pages/Loading';
import HelperService from 'services/helper.service';
import PrimusUser from 'User';
import LocalizationComponent from 'components/Localization';

import CookieConsent from 'components/CookieConsent';
import ObserverService from 'services/observer.service';
import LocalizationService from 'services/localization.service';
import ModalPage from 'pages/Modal';
import ModalPageFull from 'pages/ModalFull';

import { isIE, isEdge } from 'react-device-detect';
import { Lottie } from '@crello/react-lottie'
import animationData from 'assets/animations/ONE_PR1MUS_V01.json';

const history = require('history').createBrowserHistory();

history.listen(location => {
  const currentUrl = `${location.pathname}${location.search}${location.hash}`;
  const oldUrl = HelperService.getPreviousUrl();

  HelperService.log(
    `» Old URL: ${oldUrl} New URL: ${currentUrl}`,
    'black; font-weight: bold;',
  );

  if (currentUrl === '/entrar' && PrimusUser.hasSession()) {
    ObserverService.link.navigation.next('/');
    return false;
  }

  if (currentUrl === oldUrl) {
    ObserverService.link.header.menu.next(false);
    return false;
  }

  HelperService.saveCurrentUrl(currentUrl);

  ObserverService.link.navigation.next(currentUrl);
  HelperService.log(`Navigation to: ${currentUrl}`, 'blue');
  ObserverService.link.footer.show.next(false);

  // Apresenta ou oculta o icone de idioma na homepage
  if (currentUrl === '/' && PrimusUser.hasSession()) {
    ObserverService.link.header.lang.next(true);
  } else {
    ObserverService.link.header.lang.next(false);
  }

  let basicUrl = currentUrl;
  basicUrl = basicUrl.split('/');
  basicUrl = basicUrl[1];

  if (
    ['perfil', 'gestao-utilizadores', 'utilizador'].indexOf(basicUrl) > -1 &&
    PrimusUser.hasSession()
  ) {
    ObserverService.link.header.user.next(true);
    ObserverService.link.header.call.next(false);
  } else {
    ObserverService.link.header.user.next(false);
    ObserverService.link.header.call.next(true);
  }

  if (['notificacoes'].indexOf(basicUrl) === -1 && PrimusUser.hasSession()) {
    ObserverService.link.header.showNotification.next(true);
  } else {
    ObserverService.link.header.showNotification.next(false);
  }

  ObserverService.link.modal.next(false);
  ObserverService.link.largeModal.next(false);
});

const LoadingPageComponent = <LoadingPage controlled={false} show={true} />;
const HeaderComponent = lazy(() => import('./components/HeaderComponent'));
const FooterComponent = lazy(() => import('./components/FooterComponent'));
const NotFoundPage = lazy(() => import('./pages/NotFound'));
const CriticalError = lazy(() => import('./pages/CriticalError'));
const UnauthorizedAccess = lazy(() => import('./pages/UnauthorizedAccess'));

const notFoundProps = {
  name: 'Not Found Page',
  translateKey: null,
  component: 'NotFoundPage',
  exact: true,
  path: 'notfound',
  key: '404NotFound',
  public: true,
  onlypublic: false,
  inMenuLogged: false,
  inMenuPublic: false,
  showHeader: false,
  showFooter: false,
};

const criticalErrorProps = {
  name: 'Critical Error Page',
  translateKey: null,
  component: 'CriticalErrorPage',
  exact: true,
  key: 'CriticalError',
  public: true,
  onlypublic: false,
  inMenuLogged: false,
  inMenuPublic: false,
  showHeader: false,
  showFooter: false,
};

const unauthorizedAccessProps = {
  name: 'Unauthorized Access Page',
  translateKey: null,
  component: 'UnauthorizedAccessPage',
  exact: true,
  key: 'UnauthorizedAccess',
  public: true,
  onlypublic: false,
  inMenuLogged: false,
  inMenuPublic: false,
  showHeader: false,
  showFooter: false,
};

const initialAnimationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  renderer: 'svg'
};

export default class ReactRouter extends React.Component {
  state = {
    redirectTo: null,
    showRedirect: false,
    showHeader: true,
    showFooter: true,
    showLoader: false,
    behaviours: {},
    modalPageContent: false,
    footerContent: false,
    modalPageVisible: false,
    preventRedirect: false,
    landscape: null,
    showInitialAnimation: undefined
  };

  scrolledTimeout = 0;
  orientationTimer = 0;

  constructor(props) {
    super(props);
    LocalizationService.bootstrap(this);
    this.previousScrollPosition = 0;
  }

  readDeviceOrientation = () => {
    if (this.orientationTimer) {
      clearTimeout(this.orientationTimer);
    }
    this.orientationTimer = setTimeout(this.handleScreenChange, 550);
  };

  handleScreenChange = e => {
    // Device orientation
    let orientation = null;

    if (HelperService.isAndroid()) {
      orientation = Math.abs(window.orientation) || 0;
    } else {
      orientation = window.innerHeight < window.innerWidth;
    }

    let width = window.matchMedia('(max-width: 920px)').matches;
    let isMobile = HelperService.isMobile() && !HelperService.isTablet();

    let isLandscape = isMobile && width && orientation;
    if (isLandscape) {
      HelperService.vibrate(1500);
    }
    this.setState({ landscape: isLandscape });

    // Screen resize on Android (soft keyboard)
    let height = window.screen.height || 0;
    let viewportHeight = window.innerHeight || 0;
    let body = document.querySelector('body');

    if (HelperService.isAndroid() && viewportHeight < height / 2) {
      body.classList.add('hide-navigation');
    } else {
      body.classList.remove('hide-navigation');
    }
  };

  scrollShadow = () => {
    try {
      let main = document.querySelector('main');
      let isAuthentication = document.querySelector('.body-image');

      if (!isAuthentication) {
        if ('scrollTop' in main && main.scrollTop > 0) {
          main.classList.add('top-on');
        } else {
          main.classList.remove('top-on');
        }

        if (main.scrollHeight > main.offsetHeight) {
          main.classList.add('bottom-on');
        }
        if (
          'scrollTop' in main &&
          Math.ceil(main.offsetHeight + main.scrollTop) >= main.scrollHeight
        ) {
          main.classList.remove('bottom-on');
        }
      }
    } catch (e) {
      // Isto rebenta no IE
    }
  };

  detectNetworkAvailability = () => {
    let errorText = this.__('ERROR_CON');

    const showOnLineStatus = () => {
      HelperService.vibrate(1500);
      HelperService.showToast('SUCCESS_CON', 'success');
    };

    const showOffLineStatus = () => {
      errorText = this.__('ERROR_CON');
      HelperService.showToast(errorText, 'error', true);
      HelperService.vibrate(1500);
    };

    window.addEventListener('offline', showOffLineStatus);
    window.addEventListener('online', showOnLineStatus);
  };

  detectDeviceResize = () => {
    window.addEventListener('resize', this.readDeviceOrientation, true);
  };

  detectDeviceScroll = () => {
    document.getElementsByTagName('main')[0].addEventListener('scroll', () => {
      this.scrollShadow();

      this.previousScrollPosition = document.getElementsByTagName('main');

      if (this.previousScrollPosition) {
        this.previousScrollPosition = this.previousScrollPosition.item(
          0,
        ).scrollTop;
      } else {
        this.previousScrollPosition = 0;
      }

      if (this.scrolledTimeout && this.scrolledTimeout > 0) {
        clearTimeout(this.scrolledTimeout);
      }

      this.scrolledTimeout = 0;
      document.getElementById('scroll_css').innerHTML =
        'main::-webkit-scrollbar, main::-webkit-scrollbar-thumb { visibility: visible !important; opacity: 1 !important; }';
      this.scrolledTimeout = setTimeout(() => {
        document.getElementById('scroll_css').innerHTML =
          'main::-webkit-scrollbar, main::-webkit-scrollbar-thumb { visibility: hidden !important; opacity: 0 !important; }';
      }, 1000);
    });
  };

  detectMouseAndKey = () => {
    // Detect mouse/keyboard events (accessibility)
    document.body.addEventListener('mousedown', function () {
      document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', function () {
      document.body.classList.remove('using-mouse');
    });
  };

  componentDidMount = () => {
    if (window.location.pathname === '/entrar' && PrimusUser.hasSession()) {
      window.location.href = '/';
      return false;
    }

    this.allowStandAloneApp();
    this.handleScreenChange();
    this.detectDeviceResize();
    this.detectDeviceScroll();
    this.detectMouseAndKey();
    this.boot();
    this.detectNetworkAvailability();

    const userLoggedOut = HelperService.getCookie('userLoggedOut');
    HelperService.log(`userLoggedOut: ${userLoggedOut}`, 'blue');

    if (!isIE && !isEdge && !userLoggedOut) {
      this.setState({ showInitialAnimation: true });
      setTimeout(() => {
        this.setState({ showInitialAnimation: false })
      }, 2500);
    }

    HelperService.setCookie('userLoggedOut', false);

    const currentLanguage = HelperService.getCookie('currentLanguage');
    document.getElementsByTagName('html')[0].lang = currentLanguage;
  };

  allowStandAloneApp = () => {
    let deferredPrompt;
    window.allowAppDownload = false;

    window.requestAppDownload = () => {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          deferredPrompt = null;
          window.allowAppDownload = false;
          HelperService.gtm({
            event: 'Accepted App Download and Install'
          });
          window.requestAppDownload = () => {
            HelperService.log("User has downloaded the app in the past!", "green");
          };
          ObserverService.link.modal.next(false);
        } else {
          HelperService.gtm({
            event: 'Rejected App Download and Install'
          });
          ObserverService.link.modal.next(false);
          return false;
        }
      });
    };

    const reqFunc = (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      window.allowAppDownload = true;
    };

    window.addEventListener('beforeinstallprompt', reqFunc);
    window.onbeforeinstallprompt = reqFunc;
  };

  UNSAFE_componentWillUpdate = () => {
    PrimusUser.increaseUserTimer();
  };

  componentDidUpdate = () => {
    // RollBack the redirect
    if (this.state.showRedirect) {
      this.setState({
        showRedirect: false,
        redirectTo: false,
      });
    }

    window.history.pushState(null, document.title, window.location.href);

    this.scrollShadow();

    HelperService.applyInputPrototypes();
  };

  componentDidCatch = e => {
    HelperService.log(e, 'red');
    if (HelperService.getEnv('environment') !== 'dev') {
      window.location.href = '/criticalerror';
    } else {
      window.alert('Erro critico!');
    }
  };

  boot = () => {
    // Manage the Loading:
    ObserverService.link.loader.subscribe(show => {
      this.setState({ showLoader: show });
    });

    // Manage the redirects:
    ObserverService.link.redirect.subscribe(url => {
      if (url) {
        HelperService.log(`Redirect to: ${url}`);
        document.body.classList.remove('body-image');

        this.setState({
          redirectTo: url,
          showRedirect: true,
          showFooter: false,
        });
      }
    });

    // Manage the Header
    ObserverService.link.header.show.subscribe(visible => {
      this.setState({ showHeader: visible });
    });

    // Manage the Footer
    ObserverService.link.footer.show.subscribe(component => {
      this.setState({
        showFooter: component ? true : false,
        footerContent: component || false,
      });
    });

    // Manage the Toasts
    ObserverService.link.toast.subscribe(data => {
      if (data) {
        toast(
          <LocalizationComponent
            message={data.message}
            literal={data.literal}
          />,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 8000,
            type: data.type || 'info',
          },
        );
      }
    });

    // Manage the Modals
    ObserverService.link.modal.subscribe(component => {
      if (component.component) {
        this.setState({
          modalPageVisible: true,
          modalPageContent: component.component,
          modalPageExit: component.close ? component.close : () => { },
        });
      } else {
        if (this.state.modalPageExit) {
          this.setState({
            modalPageExit: false,
          });
        }

        this.setState({
          modalPageVisible: false,
          modalPageContent: '',
          modalPageExit: false,
        });
      }
    });

    // Manage the LARGE Modals
    ObserverService.link.largeModal.subscribe(component => {
      if (component.component) {
        this.setState({
          largeModalPageVisible: true,
          largeModalPageContent: component.component,
          largeModalPageExit: component.close ? component.close : () => { },
        });
      } else {
        if (this.state.largeModalPageExit) {
          this.setState({
            largeModalPageExit: false,
          });
        }

        this.setState({
          largeModalPageVisible: false,
          largeModalPageContent: '',
          largeModalPageExit: false,
        });
      }
    });
  };

  keyDownListener = key => {
    if (key.key && key.key.toUpperCase() === 'TAB') {
      key.preventDefault();
      return false;
    }
  };

  printRouter = () => {
    const toReturn = [];

    ProjectRoutes.routes.map(v => {
      const onlyUsers = !v.public;
      const onlyPublic = v.onlypublic;
      const RenderTheComponent = AllAvailableResources.default[v.component];

      if (PrimusUser.hasSession()) {
        if (onlyUsers) {
          // Show
          toReturn.push(
            <Route
              key={v.key}
              path={v.path}
              exact={v.exact}
              render={props => {
                return <RenderTheComponent routeProp={v} {...props} />;
              }}
            />,
          );
        }
      } else {
        // No session
        if (!onlyUsers && onlyPublic) {
          // Show
          toReturn.push(
            <Route
              key={v.key}
              path={v.path}
              exact={v.exact}
              render={props => {
                return <RenderTheComponent routeProp={v} {...props} />;
              }}
            />,
          );
        }
      }
      return '';
    });

    if (PrimusUser.hasSession()) {
      toReturn.push(
        <Route
          key={'NotFoundPageKey'}
          render={props => {
            return <NotFoundPage routeProp={notFoundProps} {...props} />;
          }}
        />,
      );
    } else {
      toReturn.push(
        <Route
          key={'Login'}
          render={() => {
            return <Redirect key="Login" to="/entrar" />;
          }}
        />,
      );
    }
    return toReturn;
  };

  render() {
    return this.state.showInitialAnimation ? (
      <Lottie
        config={initialAnimationOptions}
        className="lottie-initial"
      />
    ) : (
        <Router history={history} className={this.landscape ? 'screen-lock' : ''}>
          <LoadingPage controlled={true} show={this.state.showLoader} />
          <Suspense fallback={LoadingPageComponent}>
            {this.state.landscape ? <OrientationLock /> : null}
            <HeaderComponent isVisible={this.state.showHeader} />
            <main>
              <Switch>
                {this.state.showRedirect ? (
                  <Redirect to={this.state.redirectTo} />
                ) : null}
                <Route
                  path={'/criticalerror'}
                  key={'CriticalErrorPageKey'}
                  exact={true}
                  render={props => {
                    return (
                      <CriticalError routeProp={criticalErrorProps} {...props} />
                    );
                  }}
                />
                <Route
                  path={'/unauthorizedaccess'}
                  key={'UnauthorizedAccessPageKey'}
                  exact={true}
                  render={props => {
                    return (
                      <UnauthorizedAccess routeProp={unauthorizedAccessProps} {...props} />
                    );
                  }}
                />
                {this.printRouter()}
              </Switch>
            </main>
            <FooterComponent
              component={this.state.footerContent}
              isVisible={this.state.showFooter}
            />
            <CookieConsent />
          </Suspense>
          <ToastContainer className="custom-toast" />
          <ModalPage
            show={this.state.modalPageVisible}
            component={this.state.modalPageContent}
            onHide={() => {
              this.setState({ modalPageVisible: false });
              if (this.state.modalPageExit) {
                this.state.modalPageExit();
                this.setState({
                  modalPageExit: false,
                });
              }
            }}
          />
          <ModalPageFull
            show={this.state.largeModalPageVisible}
            component={this.state.largeModalPageContent}
            onHide={() => {
              this.setState({ largeModalPageVisible: false });
              if (this.state.largeModalPageExit) {
                this.state.largeModalPageExit();
                this.setState({
                  largeModalPageExit: false,
                });
              }
            }}
          />
        </Router>
      );
  }
}
