import 'core-js/stable';
import 'regenerator-runtime/runtime';
import smoothscroll from 'smoothscroll-polyfill';

import ReactDOM from 'react-dom';
import React from 'react';
import 'assets/scss/index.scss';
import WebFont from 'webfontloader';
import * as serviceWorker from './serviceWorker';
import * as UsedFonts from './config/fonts.json';
import ReactRouter from './Router';
import { isIE, isEdge } from 'react-device-detect';

WebFont.load(UsedFonts.fonts);

if (isIE || isEdge) {
  smoothscroll.polyfill();
}

ReactDOM.render(<ReactRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
