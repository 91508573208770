import React from 'react';
import { Modal } from 'react-bootstrap';
import './index.scss';
import LocalizationService from 'services/localization.service';
import HelperService from 'services/helper.service';

export default class ModalPageFull extends React.Component {
  constructor(props) {
    super(props);
    LocalizationService.bootstrap(this);
  }

  scrollShadow = () => {
    try {
      let main = document.querySelector('.modal-body');
      let mainDiv = document.querySelector('.modal-body > div');
      if ('scrollTop' in main && main.scrollTop > 0) {
        mainDiv.classList.add('top-on');
      } else {
        mainDiv.classList.remove('top-on');
      }
      if (main.scrollHeight > main.offsetHeight) {
        mainDiv.classList.add('bottom-on');
      }
      if (
        'scrollTop' in main &&
        Math.ceil(main.offsetHeight + main.scrollTop) >= main.scrollHeight
      ) {
        mainDiv.classList.remove('bottom-on');
      }
    } catch (e) {
      // Isto rebenta no IE
    }
  };

  scrolledTimeoutModal = 0;

  UNSAFE_componentWillUnmount = () => {
    this.applyScrolls(true);
  };

  applyScrolls = (remove = false) => {
    if (document.getElementsByClassName('modal full')[0]) {
      if (
        document
          .getElementsByClassName('modal full')[0]
          .getElementsByClassName('modal-body')[0]
      ) {
        if (remove) {
          document
            .getElementsByClassName('modal full')[0]
            .getElementsByClassName('modal-body')[0]
            .removeEventListener('scroll', this.scrollListener);
          return true;
        } else {
          document
            .getElementsByClassName('modal full')[0]
            .getElementsByClassName('modal-body')[0]
            .addEventListener('scroll', this.scrollListener);
        }
      } else {
        setTimeout(this.applyScrolls, 100);
      }
    } else {
      setTimeout(this.applyScrolls, 200);
    }
  };

  scrollListener = () => {
    if (this.scrolledTimeoutModal && this.scrolledTimeoutModal > 0) {
      clearTimeout(this.scrolledTimeoutModal);
    }
    this.scrolledTimeoutModal = 0;
    document.getElementById('scroll_modal').innerHTML =
      '.modal.full .modal-body::-webkit-scrollbar, .modal.full .modal-body::-webkit-scrollbar-thumb { visibility: visible !important; opacity: 1 !important; }';
    this.scrolledTimeoutModal = setTimeout(() => {
      document.getElementById('scroll_modal').innerHTML =
        '.modal.full .modal-body::-webkit-scrollbar, .modal.full .modal-body::-webkit-scrollbar-thumb { visibility: hidden !important; opacity: 0 !important; }';
    }, 1500);

    this.scrollShadow();
  };

  UNSAFE_componentWillUpdate = e => {
    if (e.show === true) {
      if (document.getElementsByClassName('header-component').length > 0) {
        document
          .getElementsByClassName('header-component')[0]
          .classList.add('modal-show');
      }
      this.applyScrolls();
    } else {
      if (document.getElementsByClassName('header-component').length > 0) {
        document
          .getElementsByClassName('header-component')[0]
          .classList.remove('modal-show');
      }
      this.applyScrolls(true);
    }
  };

  render() {
    return (
      <Modal
        {...this.props}
        aria-labelledby="modal"
        autoFocus
        className="full"
        backdrop={false}
        centered
        restoreFocus
        scrollable
        onHide={() => {
          if (HelperService.preventExit(false)) {
            return false;
          } else {
            HelperService.showLargeModal(false);
            return true;
          }
        }}
      >
        <Modal.Header closeButton closeLabel={this.__('TOOLTIP_CLOSE')}>
          <div
            className="logo-area"
            onClick={() => {
              HelperService.gtm({
                event: "Close Large Modal",
                target: "Landing Page"
              });

              if (HelperService.redirect('/')) {
                HelperService.showLargeModal(false);
              }
            }}
          />
        </Modal.Header>
        <Modal.Body>{this.props.component}</Modal.Body>
      </Modal>
    );
  }
}
