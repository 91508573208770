import React from 'react';
import LocalizationService from 'services/localization.service';

export default class LocalizationComponent extends React.Component {
  constructor(props) {
    super(props);
    LocalizationService.bootstrap(this);
  }

  render() {
    const message = this.props.literal
      ? this.props.message
      : this.__(this.props.message);
    return message;
  }
}
