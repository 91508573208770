import React from 'react';
import { Modal } from 'react-bootstrap';
import './index.scss';
import LocalizationService from 'services/localization.service';

export default class ModalPage extends React.Component {
  constructor(props) {
    super(props);
    LocalizationService.bootstrap(this);
  }

  render() {
    return (
      <Modal
        {...this.props}
        aria-labelledby="modal"
        autoFocus
        centered
        restoreFocus
      >
        <Modal.Header closeButton closeLabel={this.__('TOOLTIP_CLOSE')} />
        <Modal.Body>{this.props.component}</Modal.Body>
      </Modal>
    );
  }
}
