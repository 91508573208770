import React from 'react';
import { ReactComponent as Loading } from 'assets/icons/loading.svg';
import './index.scss';

export default class LoadingPage extends React.Component {
  // https://www.npmjs.com/package/react-loader-spinner
  // example: https://mhnpd.github.io/react-loader-spinner/?path=/story/loader--rings
  state = {
    displayed: null,
    controlled: false,
  };

  constructor(props) {
    super(props);
    this.state.controlled = this.props.controlled ? true : false;
    this.state.displayed = this.props.show || null;
  }

  theClasses = () => {
    if (this.props && this.props.controlled === true) {
      return 'vertical-center backdrop';
    } else {
      return 'vertical-center';
    }
  };

  theContent = (
    <div className="loading-full-page fade-in">
      <div className={this.theClasses()}>
        <div className="spinner">
          <Loading />
        </div>
      </div>
    </div>
  );

  render() {
    return this.props.controlled === true
      ? this.props.show === true
        ? this.theContent
        : null
      : this.theContent;
  }
}
