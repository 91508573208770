import { lazy } from 'react';
import * as Routemap from 'config/routes.json';

const duplicatedModules = [];
const parsedModules = [];

let routeBuilder = value => {
  if (duplicatedModules.indexOf(value.key) === -1) {
    const tmpDir = '' + value.directory + '';
    const tmpModule = lazy(() => import('./' + tmpDir));
    duplicatedModules.push(value.key);
    parsedModules[value.component] = tmpModule;
  }
};

Routemap.routes.map(routeBuilder);
export default parsedModules;
