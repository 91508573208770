import { Base64 } from 'js-base64';
import ObserverService from 'services/observer.service';
import { Linking } from 'react-native';
import {
  isMobile,
  isTablet,
  isSmartTV,
  isWearable,
  isConsole,
  isAndroid,
  isWinPhone,
  isIOS,
  isChrome,
  isIE,
  isEdge,
} from 'react-device-detect';
import ConfigurationProvider from 'services/configuration.service';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';

class HelperService {
  state = {
    debug: true,
    defaultTimeout: 0,
    allowNextURL: true,
    pageState: {},
  };

  constructor() {
    this.state.pageState = {};
    ObserverService.link.leavePage.subscribe(data => {
      this.state.allowNextURL = data;
    });
  }

  showLoader = () => {
    ObserverService.link.loader.next(true);
  };

  hideLoader = () => {
    ObserverService.link.loader.next(false);
  };

  getDefaultMessageTimeout = () => {
    return this.state.defaultTimeout;
  };

  bindLoad = _self => {
    if (_self && _self.props && typeof _self.props.routeProp !== 'undefined') {
      const retObject = {
        showHeader: _self.props.routeProp.showHeader,
        showFooter: _self.props.routeProp.showFooter,
      };

      _self._previousPageState = retObject;
      ObserverService.link.header.show.next(_self.props.routeProp.showHeader);

      // Prevent access to pages from users:
      if (_self.props.routeProp.requiredPermission) {
        const PU = require('User');
        _self._primusUser = PU.default;
        const requiredPermission = _self.props.routeProp.requiredPermission;
        if (_self._primusUser.hasSession()
          && !_self._primusUser.isPermissionAccessAllowed(requiredPermission)) {
          this.redirect('/unauthorizedaccess');
        }
      }
    }
  };

  bindUnload = _self => {
    if (typeof _self.props.routeProp !== 'undefined') {
      if (_self._previousPageState) {
        ObserverService.link.header.show.next(
          _self._previousPageState.showHeader,
        );
        ObserverService.link.footer.show.next(
          _self._previousPageState.showFooter,
        );
      }
    }
  };

  thousandsSeparator = num => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  formatCurrency = (
    value = 0.0,
    currency = 'EUR',
    fractionDigits = 2,
    country = this.getCookie('currentLanguage'),
  ) => {
    let style = currency ? 'currency' : 'decimal';
    // https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
    let options = {};
    if (style === 'currency') {
      options = {
        style: style,
        currency: currency || 'EUR',
        useGrouping: true,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      };
    } else {
      options = {
        useGrouping: true,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      };
    }

    value = value || 0.0;

    value = parseFloat(value).toFixed(fractionDigits);

    const testing = value.toString().replace('.', '');
    if (testing.length <= fractionDigits) {
      value = '0.' + testing.padStart(fractionDigits, 0);
    }

    const parsedValue = (parseFloat(value) * 1).toFixed(fractionDigits);
    const retObj = parseFloat(parsedValue).toLocaleString(country, options);

    let wPP = '';

    if (parsedValue > 999) {
      const tmp = retObj.split(',');
      const firstP = tmp[0];
      let wP = firstP;

      if (firstP.length > 3) {
        wP = wP
          .split('')
          .reverse()
          .join('')
          .replace(/([^\d])+/gim, '');
        for (let i = 0, m = wP.length; i < m; i += 3) {
          wPP += wP.substr(i, 3) + ' ';
        }
        wPP = wPP
          .trim()
          .split(' ')
          .join('.')
          .split('')
          .reverse()
          .join('');

        wPP = wPP + ',' + tmp[1];
      } else {
        wPP = retObj;
      }
    } else {
      wPP = retObj;
    }

    return wPP;
  };

  formatDate = (
    date = new Date(),
    options = {
      //weekday: 'long',
      year: 'numeric',
      //month: 'long',
      month: '2-digit',
      //day: 'numeric',
      day: '2-digit',
    },
    country = this.getCookie('currentLanguage'),
  ) => {
    // https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
    // To Locale String gives a better output than To Locale Date String ....
    if (!date instanceof Date) {
      throw new Error(`This is not a valid Date() object.`);
    }

    //return date.toLocaleString(
    return date.toLocaleDateString(
      country || this.getCookie('currentLanguage'),
      options,
    );
  };

  dateIsOneDayAgo = date => {
    if (typeof date !== Date) {
      date = new Date(Date.parse(date));
    }

    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = Math.floor(seconds / 3600);

    return interval > 24;
  };

  format24hTimeSpan = (date, hourFormat, minutesFormat, secondsFormat) => {
    if (typeof date !== Date) {
      date = new Date(Date.parse(date));
    }

    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = Math.floor(seconds / 3600);

    if (interval >= 1) {
      return hourFormat.replace('{h}', interval);
    }

    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return minutesFormat.replace('{m}', interval);
    }

    return secondsFormat.replace('{s}', interval);
  };

  /**
  * gets previous month for a given {@param date}
  * @param {Date} date
  * @returns {Date} previous month
  */
  getPreviousMonth = (date) => {
    var thisMonth = date.getMonth();

    // set the month index of the date by subtracting nofMonths from the current month index
    date.setMonth(thisMonth - 1);

    // move to the last day of the previous month
    while (date.getMonth() === thisMonth) {
      date.setDate(date.getDate() - 1);
    }

    return date;
  };

  redirect = (url, force = false) => {
    this.log(`Requested redirect to: ${url} (force: ${force})`);
    if (!force) {
      if (this.preventExit(url)) {
        this.gtm({
          event: 'Confirm exit page Popup',
          page: 'Confirm exit popup'
        });
        this.vibrate([150, 50, 150, 50, 150, 50]);
        return false;
      } else {
        ObserverService.link.redirect.next(url);
      }
    } else {
      ObserverService.link.redirect.next(url);
    }
    return true;
  };

  scrollToRef(ref, context) {
    if (
      ref &&
      typeof ref.current !== 'undefined' &&
      typeof ref.current.scrollIntoView !== 'undefined'
    ) {
      this.handleScrollToElement(ref.current, context);
    }
  }

  handleScrollToElement = (theRef, context, top = false) => {
    setTimeout(() => {
      if (
        context.refs[theRef] &&
        typeof context.refs[theRef].scrollIntoView !== 'undefined'
      ) {
        if ((isIE || isEdge) && !isMobile) {
          const calcedDiff = window.innerHeight / 4;

          if (isEdge) {
            if (ObserverService.isLargeModalActive()) {
              document
                .getElementsByClassName('fade full modal show')
                .item(0)
                .getElementsByClassName('modal-body')
                .item(0)
                .scrollBy({
                  top: context.refs[theRef].offsetTop,
                  left: 0,
                  behavior: 'smooth',
                });
            } else {
              // Works good
              document
                .getElementsByTagName('main')
                .item(0)
                .scrollBy({
                  top:
                    context.refs[theRef].offsetTop -
                    calcedDiff -
                    document.getElementsByTagName('main').item(0).scrollTop,
                  left: 0,
                  behavior: 'smooth',
                });
            }
          } else {
            if (ObserverService.isLargeModalActive()) {
              document
                .getElementsByClassName('fade full modal show')
                .item(0)
                .getElementsByClassName('modal-body')
                .item(0)
                .scrollTo(0, context.refs[theRef].offsetTop - calcedDiff);
            } else {
              // Works good
              document
                .getElementsByTagName('main')
                .item(0)
                .scrollTo(0, context.refs[theRef].offsetTop - calcedDiff);
            }
          }
        } else {
          // Works good
          top = top ? 'start' : false;
          context.refs[theRef].scrollIntoView({
            behavior: 'smooth',
            block: top ? top : 'center',
            inline: 'start',
          });
        }
      } else {
        console.error('Device does not handle SCROLL_INTO_VIEW');
      }

      this.vibrate(100);
    }, 250);
  };

  handleScrollToElementMargin = (theRef, context, top = true, margin = 0) => {
    setTimeout(() => {
      if (
        context.refs[theRef] &&
        typeof context.refs[theRef].scrollIntoView !== 'undefined'
      ) {
        const data = {
          behavior: 'smooth',
          top:
            context.refs[theRef].offsetTop -
            (window.innerHeight * margin) / 100,
          left: 0,
        };
        if (ObserverService.isLargeModalActive()) {
          document
            .getElementsByClassName('fade full modal show')
            .item(0)
            .getElementsByClassName('modal-body')
            .item(0)
            .scrollTo(data);
        } else {
          document
            .getElementsByTagName('main')
            .item(0)
            .scrollTo(data);
        }

        this.vibrate(100);
      }
    }, 750);
  };

  setCookie = (cookieName, cookieValue, cookieTimeInDays = 30) => {
    let currentDate = new Date();
    let newExpiryDate = currentDate.setTime(
      currentDate.getTime() + cookieTimeInDays * 24 * 60 * 60 * 1000,
    );
    let newCookieValue = '';

    switch (typeof cookieValue) {
      case 'object':
      case 'array':
        newCookieValue = JSON.stringify(cookieValue);
        break;
      case 'string':
      case 'number':
      default:
        newCookieValue = cookieValue;
        break;
    }
    newCookieValue = Base64.encode(newCookieValue);

    const hasAcceptedCookies = this.getCookie('primusUserConsentCookie');

    if (hasAcceptedCookies) {
      let cookieBuilder = `${cookieName}=${newCookieValue}; expires=${newExpiryDate}; path=/`;
      document.cookie = cookieBuilder;
      // Add to LocalStorage
      if (localStorage) {
        localStorage.setItem(cookieName, newCookieValue);
      }
    } else {
      sessionStorage.setItem(cookieName, newCookieValue);
    }
  };


  setSession = (cookieName, cookieValue, cookieTimeInDays = 30) => {
    // let currentDate = new Date();
    // let newExpiryDate = currentDate.setTime(
    //   currentDate.getTime() + cookieTimeInDays * 24 * 60 * 60 * 1000,
    // );
    let newCookieValue = '';

    switch (typeof cookieValue) {
      case 'object':
      case 'array':
        newCookieValue = JSON.stringify(cookieValue);
        break;
      case 'string':
      case 'number':
      default:
        newCookieValue = cookieValue;
        break;
    }
    newCookieValue = Base64.encode(newCookieValue);

    sessionStorage.setItem(cookieName, newCookieValue);
  };


  getCookie = cookieName => {
    // Get from LocalStorage
    if (localStorage) {
      let buffer = localStorage.getItem(cookieName);
      if (buffer) {
        buffer = Base64.decode(buffer);
        if (this.isJSON(buffer)) {
          return JSON.parse(buffer);
        } else {
          return buffer;
        }
      }
    }

    // Get from SessionStorage
    if (sessionStorage) {
      let buffer = sessionStorage.getItem(cookieName);
      if (buffer) {
        buffer = Base64.decode(buffer);
        if (this.isJSON(buffer)) {
          return JSON.parse(buffer);
        } else {
          return buffer;
        }
      }
    }

    // Get from cookies
    let cookies = document.cookie.split(';');
    let needle = `${cookieName}=`;
    for (let i = 0, m = cookies.length; i < m; i++) {
      let currentCookie = cookies[i];
      while (currentCookie.charAt(0) === ' ') {
        currentCookie = currentCookie.substring(1, currentCookie.length);
        if (currentCookie.indexOf(needle) === 0) {
          let retCookieValue = currentCookie.substring(
            needle.length,
            currentCookie.length,
          );
          retCookieValue = Base64.decode(retCookieValue);
          if (this.isJSON(retCookieValue)) {
            return JSON.parse(retCookieValue);
          } else {
            return retCookieValue;
          }
        }
      }
    }
    return false;
  };

  deleteCookie = cookieName => {
    // Delete from localStorage
    if (localStorage) {
      localStorage.removeItem(cookieName);
    }

    // Delete from cookies
    let action = `${cookieName}=; Max-Age=-99999999;`;
    document.cookie = action;
    return true;
  };

  isJSON = string => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  log = (message, color = false) => {
    if (this.state.debug && this.getEnv('environment') === 'dev') {
      if (color) {
        message = '%c' + message;
      }
      console.log(message, color ? 'color:' + color : '');
    }
  };

  showToast = (message = '', type = 'info', literal = false, code = '') => {
    // https://github.com/fkhadra/react-toastify
    this.vibrate([500, 250, 500]);
    if (
      code ===
      'ParceirosNetExternal.Services.AuthenticationService.InvalidSession'
    ) {
      this.redirect('/terminar-sessao', true);
    } else {
      ObserverService.showToast(message, type, literal);
    }
  };

  checkCartaoCidadaoPT = num => {
    // https://gist.github.com/ReskatoR-FR/1bf8713f6a3f6e216b992339bb988984
    // https://gist.github.com/eresende/88562d2c4dc85b62cb0c
    const number = num.toString();

    let sum = 0,
      dum = 0,
      letter_value = {
        A: 10,
        B: 11,
        C: 12,
        D: 13,
        E: 14,
        F: 15,
        G: 16,
        H: 17,
        I: 18,
        J: 19,
        K: 20,
        L: 21,
        M: 22,
        N: 23,
        O: 24,
        P: 25,
        Q: 26,
        R: 27,
        S: 28,
        T: 29,
        U: 30,
        V: 31,
        W: 32,
        X: 33,
        Y: 34,
        Z: 35,
      },
      cc_number = number.replace(/-|\s/g, ''); // remove space and -

    cc_number = cc_number.toUpperCase();
    cc_number = [...cc_number];
    cc_number = cc_number.reverse();

    cc_number[1] = letter_value[cc_number[1]];
    cc_number[2] = letter_value[cc_number[2]];

    // forEach
    cc_number.map((v, k) => {
      if (k % 2 === 0) {
        dum = parseInt(v);
      } else {
        dum = parseInt(v) * 2;
        if (dum >= 10) dum -= 9;
      }
      sum += dum;

      return '';
    });

    return sum % 10 === 0 ? true : false;
  };

  checkNifPT = num => {
    // http://jsfiddle.net/amando96/HB6Dy/

    const nif = num.toString();
    let control, mod, added;

    if (nif.length === 9) {
      added =
        nif[7] * 2 +
        nif[6] * 3 +
        nif[5] * 4 +
        nif[4] * 5 +
        nif[3] * 6 +
        nif[2] * 7 +
        nif[1] * 8 +
        nif[0] * 9;
      mod = added % 11;

      if (mod === 0 || mod === 1) {
        control = 0;
      } else {
        control = 11 - mod;
      }

      if (nif[8] === control.toString()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  checkNISSPT = num => {
    // https://gist.github.com/dreispt/024dd11c160af58268e2b44019080bbf
    const niss = Number(num);
    const validacoes = [29, 23, 19, 17, 13, 11, 7, 5, 3, 2];
    let controle = 0;
    let final = 0;

    if (niss.toString().length !== 11) {
      return false;
    }

    if ([1, 2].indexOf(Number(niss.toString()[0])) === -1) {
      return false;
    }

    for (let i = 0; i < 10; i++) {
      controle += Number(niss.toString()[i]) * validacoes[i];
    }

    final = 9 - (controle % 10);

    return Number(niss.toString()[10]) === final;
  };

  _triggerGenericCall = async num => {
    const _self = this;
    return await Linking.canOpenURL('skype:' + num).then(
      async skypeSupportBoolean => {
        if (skypeSupportBoolean === true) {
          _self.log('Opening skype');
          this.vibrate(500);
          return Linking.openURL('skype:' + num);
        } else {
          _self.log('Opening callto');
          this.vibrate(500);
          return Linking.openURL('callto:' + num);
        }
      },
    );
  };

  makePhoneCall = async num => {
    const _self = this;
    _self.log('Checking supported protocols');

    if ((isMobile || isTablet || isWearable) && isAndroid) {
      _self.log('Android Detected');
      await Linking.canOpenURL('tel:' + num).then(async telSupportBoolean => {
        if (telSupportBoolean === true) {
          _self.log('1 opening tel');
          return Linking.openURL('tel:' + num);
        } else {
          await Linking.canOpenURL('telprompt:' + num).then(
            async telPromptSupportBoolean => {
              if (telPromptSupportBoolean === true) {
                _self.log('1 opening telprompt');
                return Linking.openURL('telprompt:' + num);
              } else {
                return this._triggerGenericCall(num);
              }
            },
          );
        }
      });
    } else if ((isMobile || isTablet || isWearable) && isIOS) {
      _self.log('iOS Detected');
      if (isChrome) {
        await Linking.canOpenURL('tel:' + num).then(
          async callToSupportBoolean => {
            if (callToSupportBoolean === true) {
              _self.log('1 opening tel');
              return Linking.openURL('tel:' + num);
            } else {
              return this._triggerGenericCall(num);
            }
          },
        );
      } else {
        await Linking.canOpenURL('callto:' + num).then(
          async callToSupportBoolean => {
            if (callToSupportBoolean === true) {
              _self.log('1 opening callto');
              return Linking.openURL('callto:' + num);
            } else {
              return this._triggerGenericCall(num);
            }
          },
        );
      }
    } else if ((isMobile || isTablet || isWearable) && isWinPhone) {
      _self.log('WindowsPhone Detected');
      await Linking.canOpenURL('callto:' + num).then(
        async callToSupportBoolean => {
          if (callToSupportBoolean === true) {
            _self.log('2 opening callto');
            return Linking.openURL('callto:' + num);
          } else {
            return this._triggerGenericCall(num);
          }
        },
      );
    } else {
      if (isSmartTV || isConsole) {
        _self.showToast('DEVICE_HAS_NO_SUPPORT', 'error');
      } else {
        return this._triggerGenericCall(num);
      }
    }
  };

  showGenericError = error => {
    this.hideLoader();
    this.log(`Erro de ligação: ` + JSON.stringify(error), 'red');
    if (typeof error === 'object') {
      if ('ErrorCode' in error) {
        if (
          error.ErrorCode ===
          'ParceirosNetExternal.Services.AuthenticationService.InvalidSession'
        ) {
          this.redirect('/terminar-sessao', true);
          return false;
        }
      }
      if (error && error.Message) {
        this.showToast([error.Message, true], 'error');
        return false;
      }
      if (error.result && error.result.Message) {
        this.showToast([error.result.Message, true], 'error');
        return false;
      }
      if (error.data && error.data.Message) {
        this.showToast([error.data.Message, true], 'error');
        return false;
      }
      if (error.result && error.result.data && error.result.data.Message) {
        this.showToast([error.result.data.Message, true], 'error');
        return false;
      }
    } else if (typeof error === 'string') {
      this.showToast(error, 'error', true);
      this.vibrate(500);
    } else {
      this.showToast('ERROR_CON', 'error');
      this.vibrate(500);
    }
    return false;
  };

  showModal = (component, closeAction = () => { }) => {
    this.vibrate(250);
    ObserverService.showModal(component, closeAction);
  };

  showLargeModal = (component, closeAction = () => { }) => {
    ObserverService.showLargeModal(component, closeAction);
  };

  setGlobalTitle = (title, literal = false) => {
    // Send false to hide
    ObserverService.setGlobalTitle(title, literal);
  };

  getGlobalTitle = () => {
    return ObserverService.getGlobalTitle();
  };

  showFooter = component => {
    ObserverService.link.footer.show.next(component);
  };

  getConfig = key => {
    return ConfigurationProvider.getConfiguration(key);
  };

  getEnv = key => {
    return process.env['REACT_APP_' + key.toUpperCase()];
  };

  calcAge = dateString => {
    const birthday = +new Date(dateString);
    const age = ~~((Date.now() - birthday) / 31557600000);

    if (age < 18) {
      return false;
    } else {
      return true;
    }
  };

  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  getPreviousUrl = () => {
    if (this.state.prevUrl) {
      return this.state.prevUrl;
    } else {
      return false;
    }
  };

  saveCurrentUrl = url => {
    this.state.prevUrl = url;
  };

  base64ToArrayBuffer = base64 => {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  };

  fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        const fileEncoded = {
          name: file.name,
          type: file.type,
          base64: encoded,
        };
        resolve(fileEncoded);
      };
      reader.onerror = error => reject(error);
    });
  };

  base64Clear = (base64, name) => {
    return new Promise(resolve => {
      const encoded = base64.toString().replace(/^data:(.*,)?/, '');
      if (encoded) {
        const fileEncoded = {
          name: `${name}.jpg`, //# O valor que vem da camera não possui nome
          type: 'image/jpeg',
          base64: encoded,
        };
        resolve(fileEncoded);
      }
    });
  };

  checkIBANpt = iban => {
    return this._checkIBAN(iban) === 1 ? true : false;
  };

  _checkIBAN = input => {
    const CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26,
    };

    const iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    let digits;

    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }

    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => {
      return letter.charCodeAt(0) - 55;
    });

    return this._checkIBANchecksum(digits);
  };

  _checkIBANchecksum = string => {
    let checksum = string.slice(0, 2),
      fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }

    return checksum;
  };

  checkMatriculaPT = matricula => {
    const res = matricula.match(
      /^(\d{2}-\d{2}-[\w]{2}||[\w]{2}-\d{2}-\d{2}||\d{2}-[\w]{2}-\d{2})$/gi,
    );
    return res ? true : false;
  };

  formatMatriculaPT = matricula => {
    if (this.checkMatriculaPT(matricula)) {
      matricula = matricula.toUpperCase();
      return matricula;
    } else {
      this.log(`A Matricula não pode ser formatada! - ${matricula}`);
      return matricula;
    }
  };

  handleMatriculaInput = value => {
    value = value.toUpperCase().replace(/[^\dA-Z-]/gim, '');
    let pieces = [];
    if (value.indexOf('-') > -1) {
      value = value.split('-').join('');
    }
    pieces = [value.substr(0, 2), value.substr(2, 2), value.substr(4, 2)];
    pieces = pieces.filter(val => (val ? true : false));
    pieces = pieces.join('-');
    return pieces.length === 1
      ? pieces + '-'
      : pieces.length === 5
        ? pieces + '-'
        : pieces;
  };

  isScreenHorizontal = () => {
    // Returns boolean
    if ('screen' in window && window.screen.orientation) {
      if ('type' in window.screen.orientation) {
        const orientation = window.screen.orientation.type.toString() || '';
        return orientation.includes('landscape');
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  applyInputPrototypes = () => {
    window.addEventListener('keyup', r => {
      if (
        (r.target && r.target.type && r.target.type === 'tel') ||
        r.target.type === 'number'
      ) {
        if (
          [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            'Backspace',
            'ArrowLeft',
            'ArrowRight',
            'ArrowDown',
            'ArrowUp',
          ].indexOf(r.key) === -1
        ) {
          r.target.value = r.target.value.replace(/[^\d,.\s-]/gim, '');
        }
      }
    });
  };

  formatIBAN = ibanString => {
    let newIban = ibanString ? ibanString.toString() : '';

    if (newIban.length > 0) {
      newIban = newIban.replace(/PT50/gim, '');
      newIban = newIban.replace(/PT5/gim, '');
      newIban = newIban.replace(/[^\d]/gim, '');
      newIban = newIban.split('');
      if (newIban.length >= 1) {
        // 50
        // 0123
        newIban.splice(0, 0, ' ');
      }

      if (newIban.length >= 6) {
        // 50 0033
        newIban.splice(5, 0, ' ');
      }

      if (newIban.length >= 11) {
        // 50 0033 0000
        newIban.splice(10, 0, ' ');
      }

      if (newIban.length >= 24) {
        // 50 0033 0000 11119112 5
        newIban.splice(23, 0, ' ');
      }

      newIban = 'PT50' + newIban.join('');
    }

    return newIban;
  };

  formatNISS = nissString => {
    let newNiss = nissString ? nissString.toString() : '';

    if (newNiss.length > 0) {
      return this.formatStringThirdPaired(newNiss);
    }

    return newNiss;
  };

  removerAcentos = string => {
    var baseChars = [];
    for (var i = 97; i < 97 + 26; i++) {
      baseChars.push(String.fromCharCode(i));
    }

    //if needed, handle fancy compound characters
    baseChars = baseChars.concat(
      'ss,aa,ae,ao,au,av,ay,dz,hv,lj,nj,oi,ou,oo,tz,vy'.split(','),
    );

    const isUpperCase = c => {
      return c !== c.toLocaleLowerCase();
    };

    const toBaseChar = (c, opts) => {
      opts = opts || {};

      if (!('locale' in opts)) opts.locale = 'pt';

      var cOpts = { sensitivity: 'base' };

      //exit early for any non-alphabetical character
      if (c.localeCompare('9', opts.locale, cOpts) <= 0)
        return opts.nonAlphaChar === undefined ? c : opts.nonAlphaChar;

      for (var i = 0; i < baseChars.length; i++) {
        var baseChar = baseChars[i];

        var comp = c.localeCompare(baseChar, opts.locale, cOpts);
        if (comp === 0 || comp === '0')
          return isUpperCase(c) ? baseChar.toUpperCase() : baseChar;
      }

      return opts.noMatchChar === undefined ? c : opts.noMatchChar;
    };

    return string.replace(/[^\w\s\d]/gi, c => {
      return toBaseChar(c);
    });
  };

  formatMatricula = mString => {
    let matriculaString = mString ? mString.toString() : '';

    if (matriculaString.length > 0) {
      matriculaString = matriculaString
        .toUpperCase()
        .replace(/[^\dA-Z]/gim, '');
      matriculaString = matriculaString.split('');
      if (matriculaString.length >= 3) {
        // 00A
        // 01234567
        // 12345678
        matriculaString.splice(2, 0, '-');
      }

      if (matriculaString.length >= 6) {
        // 00-AA0
        matriculaString.splice(5, 0, '-');
      }

      matriculaString = matriculaString.join('');
    } else {
      this.log('Matrícula is empty :(', 'blue; font-weight: bold;');
    }

    return matriculaString;
  };

  formatCurrencyNumber = (string, event) => {
    return this.formatCurrencyNumberNDecimalPlances(string, event, 2);
  };

  formatCurrencyNumberNDecimalPlances = (string, event, nDecimalPlaces) => {
    let s = string || '0,00';
    s = s.toString().replace(/\D/g, '');
    if (s.length > nDecimalPlaces) {
      s = s.split('');
      s.reverse();
      s.splice(nDecimalPlaces, 0, '.');
      s.reverse();
      s = s.join('');
      s = parseFloat(s).toFixed(nDecimalPlaces);
    } else {
      let fs = s;
      if (s.length < nDecimalPlaces) {
        while (fs.length < nDecimalPlaces) fs = '0' + fs;
      }
      s = '0.' + fs;
    }
    s = s.replace(/\D-/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (s.substr(-(nDecimalPlaces + 1), 1) === '.') {
      s = s.split('');
      s.reverse();
      s[nDecimalPlaces] = ',';
      s.reverse();
      s = s.join('');
    }

    // Disabled because of: #4194 (in comments section)

    if (event) {
      if (event.target.selectionStart !== null) {
        event.persist();
        const isBack = event.nativeEvent.inputType === 'deleteContentBackward';
        const prevCursorPosition = isBack
          ? event.target.selectionStart
          : s.length;
        //event.target.setSelectionRange(s.length, s.length);
        setTimeout(() => {
          event.target.setSelectionRange(
            prevCursorPosition,
            prevCursorPosition,
          );
          this.log('Cursor was ajusted to: ' + prevCursorPosition, 'blue');
        }, 5);
      }
    }

    return s;
  };

  formatDateYYYYMMDD = theDate => {
    if (theDate) {
      if (typeof theDate === 'string') {
        return theDate;
      }

      if (theDate instanceof Date) {
        theDate =
          theDate.getFullYear() +
          '-' +
          (theDate.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          theDate
            .getDate()
            .toString()
            .padStart(2, '0');

        return theDate;
      }
    }
  };

  isMobile = () => {
    return isMobile;
  };

  formatStringThirdPaired = iS => {
    return this.formatStringNPaired(iS, 3);
  };

  formatStringNPaired = (iS, n) => {
    if (typeof iS === 'string') {
      let t = iS.replace(/\s/gim, '').split('');
      if (t.length > 0) {
        let newString = [];
        t.map((val, index) => {
          if (index % n === 0) {
            newString.push(' ' + val);
          } else {
            newString.push(val);
          }
          return '';
        });
        return newString.join('').trim();
      } else {
        return '';
      }
    } else {
      this.log(
        'Function expects a string, but received something else!',
        'red',
      );
      console.error('Received:', iS);
      return false;
    }
  };

  vibrate = (timer = 0) => {
    if (timer) {
      if ('vibrate' in window.navigator) {
        window.navigator.vibrate(timer);
      } else {
        this.log('Vibrate not available in device', 'red');
      }
    }
  };

  isLandscape = () => {
    return window.matchMedia('(orientation: landscape)').matches || false;
  };

  isAndroid = () => {
    return isAndroid ? true : false;
  };

  isTablet = () => {
    return isTablet ? true : false;
  };

  isIOS = () => {
    return isIOS ? true : false;
  };

  preventExit = url => {
    const p = window.location.pathname || '';
    let type = null;
    let title = null;
    let subtitle = null;

    //# We should match first to prevent loading unecessary classes
    if (p.includes('/utilizador/')) {
      // Ao sair da edição de utilizador.
      type = 1;
    }

    if (p.includes('/simular') && ObserverService.isFooterActive() !== false) {
      // Ao sair da simulação.
      type = 2;
    }

    if (p.includes('/proposta/')) {
      // type = 3;
      // Uncomment this previous line to request auth on proposal close
    }

    if (
      p.includes('/proposta/') &&
      document.getElementsByClassName('fade full modal show recalculo').item(0)
    ) {
      // Recalculo
      type = 4;
    }

    // ----
    // Add more conditions here!
    // ----

    //# Dont touch the code below plz.
    switch (type) {
      case 1:
        title = ['S03.05_B1_TITLE'];
        subtitle = ['S03.05_B1_SUBTITLE'];
        break;
      case 2:
        title = ['EXIT_MSG_TITLE_SIMULATION'];
        subtitle = ['EXIT_MSG_SUBTITLE'];
        break;
      case 3:
        title = ['EXIT_MSG_TITLE_PROPOSAL'];
        subtitle = ['EXIT_MSG_SUBTITLE'];
        break;
      case 4:
        title = ['EXIT_MSG_TITLE_RECALCULATION'];
        subtitle = ['EXIT_MSG_SUBTITLE'];
        break;
      // ----
      // Add more conditions here!
      // ----
      default:
        // We must stop execution as soon as possible
        return false;
    }

    const pEcE = require('components/Modal/PageExit');
    const peC = new pEcE.default();

    peC.params(url, title, subtitle);

    // Always return something...
    return true;
  };

  formatDateInput = (txt, event) => {
    if (!txt || typeof txt !== 'string') {
      return null;
    }

    let origDashes = 0;
    let newDashes = 0;
    let applyDashes = 0;

    if (txt.length > 0) {
      origDashes = txt.match(/-/gim);
      origDashes = origDashes ? origDashes.length : 0;
      txt = txt.toUpperCase().replace(/[^\d]/gim, '');
      txt = txt.split('');
      if (txt.length > 2) {
        // 00A
        // 01234567
        // 12345678
        txt.splice(2, 0, '-');
      }

      if (txt.length > 5) {
        // 00-AA0
        txt.splice(5, 0, '-');
      }

      if (txt.length > 10) {
        txt.length = 10;
      }

      txt = txt.join('');

      newDashes = txt.match(/-/gim);
      newDashes = newDashes ? newDashes.length : 2;

      applyDashes = newDashes - origDashes;

      if (event) {
        if (event.target.selectionStart !== null) {
          event.persist();
          const isBack =
            event.nativeEvent.inputType === 'deleteContentBackward';
          const prevCursorPosition = isBack
            ? event.target.selectionStart
            : event.target.selectionStart + applyDashes; // txt.length;

          setTimeout(() => {
            event.target.setSelectionRange(
              prevCursorPosition,
              prevCursorPosition,
            );

            this.log(
              'Cursor was ajusted to: ' + prevCursorPosition,
              'yellow; background-color: red;',
            );
          }, 5);
        }
      }
    } else {
      this.log('Date is empty :(', 'green; font-weight: bold;');
    }

    return txt;
  };

  deviceIsPC = () => {
    if (
      !isMobile &&
      !isTablet &&
      !isIOS &&
      !isAndroid &&
      !isConsole &&
      !isSmartTV &&
      !isWearable &&
      !isWinPhone
    ) {
      return true;
    } else {
      return false;
    }
  };

  deviceHasCamera = async () => {
    if (navigator && 'mediaDevices' in navigator) {
      try {
        let response = false;
        let holdup = await navigator.mediaDevices
          .enumerateDevices()
          .then(s => {
            if (s && s.length > 0) {
              s.map(r => {
                if ('kind' in r) {
                  if (r.kind.indexOf('video') > -1) {
                    response = true;
                  }
                }
                return '';
              });
            } else {
              response = false;
            }
            return response;
          })
          .catch(e => {
            response = false;
            return response;
          });
        return holdup.valueOf();
      } catch (e) {
        this.log('Problemas ao ler os dispositivos deste dispositivo.', 'red');
      }
    } else {
      return false;
    }
  };

  gtm = event => {
    if (!window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
    }
    if (typeof event === 'object' && Object.keys(event).length > 0 && event['event']) {
      this.log("Firing GTM EVENT", "green; font-weight: bold;");
      this.log(event);
      window.dataLayer.push(event);
    } else {
      this.log("Please, to pass events, use the event key inside the object. {event:'some event'}", 'font-weight: bold');
    }
  };

  exportToExcel = (excelData, sheetName, fileName) => {
    //Important:
    //  excelData - All values in excelData must be formated as string,
    //              for null values fill an empty string,
    //              if your collection has no values fill one record with empty string values,
    //              the name of the columns will have the object properties name.
    //  sheetName - workheet name
    //  fileName - file name without extension

    //Create the worksheet with the data
    const ws = XLSX.utils.json_to_sheet(excelData);

    //Set columns width to fit data
    let columnsKeysWidth = [];
    let keys = Object.keys(excelData[0]);
    for (let i = 0; i < keys.length; i++) {
      columnsKeysWidth[i] = keys[i].length;
    }
    let columnsValuesWidth = [];
    for (let i = 0; i < excelData.length; i++) {
      let values = Object.values(excelData[i]);
      for (let j = 0; j < values.length; j++) {
        columnsValuesWidth[j] =
          columnsValuesWidth[j] >= values[j].length
            ? columnsValuesWidth[j]
            : values[j].length;
        if (columnsValuesWidth[j] < columnsKeysWidth[i])
          columnsValuesWidth[j] = columnsKeysWidth[i];
      }
    }
    let wsCols = [];
    columnsValuesWidth.map(col => (
      wsCols.push({ width: col })
    ));
    ws["!cols"] = wsCols;

    //Create the workbook and add the worksheet with a given name
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    //Export data to an Excel file
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);
  }
}

export default new HelperService();
