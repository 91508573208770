import * as React from 'react';
import './index.scss';
import getIcon from 'assets/icons/icons.js';

export default class Icon extends React.Component {
  render() {
    const color = this.props.color;
    const name = this.props.icon;
    const width = this.props.width || 1;

    return (
      <div
        className={`svg-icon ${color}`}
        style={{ width: `${width}rem`, height: `auto` }}
      >
        {getIcon(name)}
      </div>
    );
  }
}
