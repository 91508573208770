import API from 'config/endpoint';
import React from 'react';

class ConfigurationProvider extends React.Component {
  _state = {
    keys: [],
    data: [],
  };

  getConfiguration = key => {
    let header = { headers: { 'Content-Type': 'application/json' } };
    if (this._state.keys.indexOf(key) > -1) {
      return this._state.data[key];
    } else {
      return API.post(
        '/InternalConfiguration/GetValues',
        {
          Keys: [key],
        },
        header,
      ).then(result => {
        const data = result.data;
        if (data.length > 0) {
          let newData = [];
          let newKeys = [];
          data.map(v => {
            newData[v.Key] = v.Value;
            newKeys.push(v.Key);
            return '';
          });
          this._state.data = newData;
          this._state.keys = newKeys;
        }
        return this.getConfiguration(key);
      });
    }
  };
}

export default new ConfigurationProvider();
