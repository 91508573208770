import { BehaviorSubject } from 'rxjs';

class ObserverService {
  link = {
    header: {
      show: new BehaviorSubject(false),
      menu: new BehaviorSubject(false),
      call: new BehaviorSubject(false),
      user: new BehaviorSubject(false),
      lang: new BehaviorSubject(false),
      title: new BehaviorSubject(false),
      showNotification: new BehaviorSubject(false),
      newNotifications: new BehaviorSubject(false),
      minutesToCheckUnreadNotifications: new BehaviorSubject(1),
    },
    footer: {
      show: new BehaviorSubject(false),
    },
    loader: new BehaviorSubject(false),
    navigation: new BehaviorSubject(false),
    redirect: new BehaviorSubject(false),
    toast: new BehaviorSubject(false),
    modal: new BehaviorSubject(false),
    largeModal: new BehaviorSubject(false),
    simulation_step: new BehaviorSubject(false),
    proposition_step: new BehaviorSubject(false),
    recalculate_step: new BehaviorSubject(false),
    proponent_step_3: new BehaviorSubject(false),
    leavePage: new BehaviorSubject(true),
  };

  data = {};
  lgModal = false;

  showModal = (component, closeAction) => {
    this.link.modal.next({ component: component, close: closeAction });
  };

  showLargeModal = (component, closeAction) => {
    this.lgModal = component ? true : false;
    this.link.largeModal.next({ component: component, close: closeAction });
  };

  isLargeModalActive = () => {
    return this.lgModal &&
      document.getElementsByClassName('fade full modal show').length > 0
      ? true
      : false;
  };

  isFooterActive = () => {
    return this.link.footer.show.asObservable().source.value ? true : false;
  };

  showToast = (message = '', type = 'info', literal = false) => {
    // https://github.com/fkhadra/react-toastify
    this.link.toast.next({
      message: message,
      type: type,
      literal: literal,
    });
  };

  setGlobalTitle = (message, literal = false) => {
    if (literal) {
      const obj = { title: message };
      this.data.title = obj;
      this.link.header.title.next(obj);
    } else {
      this.data.title = message;
      this.link.header.title.next(message);
    }
  };

  getGlobalTitle = () => {
    return this.data.title || false;
  };
}

export default new ObserverService();
